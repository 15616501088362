<template>
    <div>
        <div class="ml-3 mr-3 mb-5">
            <div class="mb-3 fl-te-c">
                <h4>Add Return</h4>
                <btn size="sm" @click="$router.push('/warehouse/return/list/')" text="Back"/>
            </div>
            <div class="card bs-4 p-1">
                <div>
                    <h5 class="bb-1 p-3">Search </h5>
                </div>
                <div>
                    <b-form class="pl-3 pt-2 pr-3 pb-0" @failure="noDataAvailable" @success="formSuccess"
                            :save-url="searchOrder" ref="form"
                            v-slot="{model, loading}">
                        <div class="fl-te-c">
                            <div class="fl-eq">
                                <div class="mt-2">
                                    <h6>Enter your Purchase Id</h6>
                                </div>
                                <div class="pl-3">
                                    <validated-input type="number" name="entry_id" :rules="rules.entry_id"
                                                     v-model="model.entry_id" :disabled="loading"/>
                                </div>
                            </div>
                            <div>
                                <btn size="sm" text="Submit" :loading="loading"
                                     loading-text="Submitting..."></btn>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
        <purchase-details v-if="details" class="ml-3 mr-3 mb-5" :data="details"/>
        <div class="round-2 pb-6 text-center" v-if="noData">
            <img style="height: 12rem;" alt="No Data Available" src="../../../../assets/img/no-data.png">
            <p>No data for Purchase found</p>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import PurchaseDetails from './PurchaseDetails';

export default {
    name       : 'AddReturnComponent',
    components : { PurchaseDetails },
    data () {
        return {
            DataLoading : false,
            noData      : false,
            details     : {},
            searchOrder : urls.warehouseAdmin.purchaseReturn.details,
            rules       : {
                entry_id : {
                    required  : true,
                    min_value : 1
                }
            }
        };
    },
    methods : {
        noDataAvailable () {
            this.noData = true;
        },
        formSuccess (response) {
            this.details = response.data;
        }
    }
};
</script>

<style scoped>

.label {
    font-weight: 500;
}
</style>
